import React, { Fragment, useState } from 'react'

const FloatingButton = () => {

    const [open, setOpen] = useState(true)

    const onClickHandler = (e) => {
        setOpen(!open)
        e.target.src = '../../images/BeraterBild.jpg'
    }

    const onBildHoverInAction = (e) => {
        e.target.src = '../../images/CloseImage.jpg'
    }

    const onBildHoverOutAction = (e) => {
        e.target.src = '../../images/BeraterBild.jpg'
    }

    return <Fragment>
            {open ? (
                <div className='floating-button is-shown'>
                <img src='../../images/BeraterBild.jpg' alt='Kontaktperson genusswein Marcel Honauer' className='floating-img' onClick={(e) => onClickHandler(e)}/>
                <div className='floating-text-wrapper is-shown'>
                    <p className='floating-text'><strong>Noch Fragen?<br /></strong>Zögern Sie nicht uns zu kontaktieren.<br />Sie erreichen uns via <a href="mailto:info@genusswein.ch">E-Mail</a></p>
                </div> 
                </div>
            ) : (
                <div className='floating-button'>
                    <img src='../../images/BeraterBild.jpg' alt='closing button' className='floating-img' onClick={(e) => onClickHandler(e)} onMouseEnter={(e) => onBildHoverInAction(e)} onMouseLeave={(e) => onBildHoverOutAction(e)}/>
                    <div className='floating-text-wrapper is-shown'>
                    <p className='floating-text'><strong>Noch Fragen?<br /></strong>Zögern Sie nicht uns zu kontaktieren.<br />Sie erreichen uns via <a href="mailto:info@genusswein.ch">E-Mail</a>.</p>
                </div> 
                </div>
            )
        }
        </Fragment> 
}

export default FloatingButton