import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { Col, Row, Container } from 'react-bootstrap';
import SubZutaten from './SubZutaten';
import { updateZutatenSelection } from '../actions/productActions'
import Message from '../components/Message'


const Zutaten = ({ auswahl, menus }) => {

  const [auswahlZutatenKategorien, setAuswahlZutatenKategorien] = useState([])
  const [ausgewaehlteZutaten, setAusgewaehlteZutaten] = useState([])
  const [validationMessage, setValidationMessage] = useState('')

  const dispatch = useDispatch();

  const filteredMenu = menus.filter((e) => {return e.name === auswahl}).shift()

  useEffect(() => {
    setAuswahlZutatenKategorien(filteredMenu)
  },[auswahl, menus, filteredMenu])

  const handleCheck = (category, name) => {
    
    setValidationMessage('')
    
    let arrCopy = [...ausgewaehlteZutaten]
    const resultOfCheckArr = arrCopy.filter((element) => element === name)
    
    if (resultOfCheckArr.length !== 0) {
      if(resultOfCheckArr.length > 2){
      } else {
        let newArr = _.pull(arrCopy, name);
        setAusgewaehlteZutaten(newArr)
        dispatch(updateZutatenSelection(newArr))
      }
    } else {
      
      if(category.zutatenName === 'Fleisch' || category.zutatenName === 'Fisch') {
        let newArr = [...arrCopy, name]
        let returnArr = _.sortedUniq(newArr)
        if(newArr.length > 2 && category.zutatenName === 'Fleisch' || newArr.length > 2 && category.zutatenName === 'Fisch'){
          setValidationMessage('Wählen Sie unter der Kategorie «Fleisch» und «Fisch» max. 2 Hauptzutaten')
          return;
        }
        setAusgewaehlteZutaten(returnArr)
        dispatch(updateZutatenSelection(newArr))
      }

      let newArr = [...arrCopy, name]
      let returnArr = _.sortedUniq(newArr)
      setAusgewaehlteZutaten(returnArr)
      dispatch(updateZutatenSelection(newArr))
    }
}


  return (
    <Container className='container-no-margin'>
      {validationMessage && <Message variant='warning'>{validationMessage}</Message>}
      <Row>
        {auswahlZutatenKategorien.zutaten?.map((zutat, index) => {
            return <Col key={index}>
              <h3>{zutat.zutatenName}</h3>
              <SubZutaten auswahl={auswahl} ausgewaehlteZutaten={ausgewaehlteZutaten} zutatenKategorie={zutat} unterZutaten={zutat.subZutaten} handleCheck={handleCheck} />
            </Col>
          })
        }
      </Row>
    </Container>
  );
}

export default Zutaten