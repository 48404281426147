import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { TbCircleNumber1, TbCircleNumber2, TbCircleNumber3 } from "react-icons/tb"

const Stepper = ({colorStep1, colorStep2, colorStep3}) => {
    return (
        <section style={{ marginTop:'-155px', marginBottom: '100px' }}>
            <Col className='text-center py-3'>
                <Row>
                    <div className='section-stepper'>
                        <TbCircleNumber1 color={colorStep1} size={40}/><hr style={{width: '100px',display: 'inline-block',border: '2px solid',marginBottom:'17px',marginLeft:'-5px',marginRight:'-5px'}} /><TbCircleNumber2 color={colorStep2} size={40}/><hr style={{width: '100px',display: 'inline-block',border: '2px solid',marginBottom:'17px',marginLeft:'-5px',marginRight:'-5px'}} /><TbCircleNumber3 color={colorStep3} size={40}/>
                    </div>
                </Row>
            </Col>
        </section>
    )
}

export default Stepper
