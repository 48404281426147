import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Image, Container } from 'react-bootstrap'
import Meta from '../components/Meta'

const Welcomescreen = () => {

    return (
        <>
            <Container>
                <Meta title='Willkommen im Weinberater | genusswein GmbH' description='Gemeinsam finden wir den passenden Wein' keywords='weinberater, essen, fest, party, passend'/>
                <h1 className='pt-5' style={{marginBottom: '3rem'}}>Lass uns gemeinsam den passenden Wein für dich finden!</h1>
                <Image src='../../images/titelbild-weinberater-genusswein.jpg' alt='Titelbild Weinberater' width='750px' fluid />
                <Col sm={12} md={6} lg={4} xl={3}>
                    <h2 className='primary-color' style={{marginTop: '3rem'}}>Der digitale Weinberater hilft dir den passenden Wein für deinen Geschmack und das geplante Essen zu finden.</h2>
                </Col>
                <Link className='btn btn-big' to='/menu'>Weinberater starten</Link>
            </Container>
        </>
    )
}

export default Welcomescreen
