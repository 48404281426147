import React, { useState } from 'react'
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

const Kategorien = ({ kategorien, filterItems }) => {

  const [radioValue, setRadioValue] = useState('');

  return <div>

    <ButtonGroup>
        {kategorien.map((kategorie, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={'outline-danger'}
            name="radio"
            value={kategorie}
            checked={radioValue === kategorie}
            onChange={(e) => {
              setRadioValue(e.currentTarget.value)
              filterItems(kategorie)
            }}
          >
            {kategorie}
          </ToggleButton>
        ))}
      </ButtonGroup>

  </div>
}

export default Kategorien