import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

const SubZutaten = ({ auswahl, ausgewaehlteZutaten, zutatenKategorie, unterZutaten, handleCheck }) => {

    const [selectedButton, setSelectedButton] = useState();

    const zutatenSelectList = useSelector(state => state.zutatenSelectList);
    const { zutaten:selectedZutaten } = zutatenSelectList;

    useEffect(() => {
        if(selectedZutaten) {
          setSelectedButton(selectedZutaten)
        } else {
          setSelectedButton('')
        }
      },[selectedZutaten])

  return (
        <ButtonGroup className="mb-2">
            {unterZutaten.map((zutat, index) => {
              return <ul key={index}>
                      <li><ToggleButton className={ausgewaehlteZutaten.find((element) => element === zutat) ? 'btn border-light isActive' : selectedButton?.find((element) => element === zutat) ? 'btn border-light isActive' : 'btn border-light' } type="checkbox" value={zutat} onClick={() => handleCheck(zutatenKategorie, zutat)}>{ausgewaehlteZutaten.find((element) => element === zutat) ? `✓ ${zutat}` : `${zutat}`}</ToggleButton></li>
                     </ul>
            })}
          </ButtonGroup>  
  )
}

export default SubZutaten