import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import Meta from '../components/Meta'
import Message from '../components/Message'
import { updateCourseSelection, updateFoodSelection, updateZutatenSelection  } from '../actions/productActions'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import Stepper from '../components/Stepper'
import Speisen from '../components/Speisen'
import menus from '../data/menus'
import Kategorien from '../components/Kategorien'
import FloatingButton from '../components/FloatingButton'

const Menuscreen = () => {

    const allCategories = [...new Set(menus.map((item) => item.category))]

    const [showGang1, setShowGang1] = useState(true)
    const [showInfoAcc1, setShowInfoAcc1] = useState()
    const [kategorien, setKategorien] = useState(allCategories)
    const [speisen, setSpeisen] = useState(menus)
    const [selectedCourse, setSelectedCourse] = useState()
    const [selectedFood, setSelectedFood] = useState()
    const [selectedZutaten, setSelectedZutaten] = useState()
    const [validation, setValidation] = useState()

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const courseSelectList = useSelector(state => state.courseSelectList);
    const { course } = courseSelectList;

    const foodSelectList = useSelector(state => state.foodSelectList);
    const { food } = foodSelectList;

    const zutatenSelectList = useSelector(state => state.zutatenSelectList);
    const { zutaten } = zutatenSelectList;

    const filterItems = (category) => {
        setSpeisen(menus)
        setShowInfoAcc1(true)
        setValidation()
        if(category === 'Alle') {
            setSpeisen(menus)
            dispatch(updateCourseSelection(null))
            return
        }
        dispatch(updateCourseSelection(category))
        dispatch(updateFoodSelection(''))
        dispatch(updateZutatenSelection([]))
        const newSpeisenItems = menus.filter((item) => item.category === category)
        setSpeisen(newSpeisenItems)
    }

    useEffect(() => {
        if(food) {
            setSelectedFood(food)
        } else {
            if(!food || !zutaten ) {
                setSelectedFood('')
                setSelectedZutaten('')
            } else {
                setSelectedZutaten(zutaten)
            }
        }
    }, [dispatch, zutaten, food, validation])

    const handleSubmit = () => {
        if (!course && !food && !zutaten) {
            setValidation('Bitte treffen Sie eine Auswahl')
        } else if (!course) {
            setValidation('Bitte wählen Sie die Art des Ganges')
            window.scrollTo({ top: 210, behavior: 'smooth',})
        } else if (!food) {
            setValidation('Bitte wählen Sie ein Menü')
            window.scrollTo({ top: 210, behavior: 'smooth',})
        } else if (zutaten?.length === 0) {
            setValidation('Bitte wählen Sie mindestens eine Haupt-Zutaten aus')
            window.scrollTo({ top: 210, behavior: 'smooth',})
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth',})
            navigate('/empfehlung')
        }
    }

    return (
        <>
            <Meta title='Weinberater für Ihr Menu | genusswein GmbH' description='Gemeinsam finden wir den passenden Wein' keywords='weinberater, essen, fest, party, passend'/>
            <Stepper colorStep1='#6e150e'/>
            
            <main className='container'>
                <h1 className='padding-bottom-medium'>Menü / Gänge</h1>
                <section className='info'>
                    {validation && <Message variant='danger'>{validation}</Message>}

                    {showGang1 && 
                    
                        <article className='question'>
                        <header>
                        <h3>1. Gang</h3>
                        <p>Wählen Sie die Art des Ganges:</p><Kategorien kategorien={kategorien} filterItems={filterItems} accState={showInfoAcc1}/>
                        
                        <button className='btn-round' onClick={() => setShowInfoAcc1(!showInfoAcc1) }>
                            {showInfoAcc1 ? <AiOutlineMinus /> : <AiOutlinePlus />}
                        </button>
                        </header>
                        {showInfoAcc1 && food && <div className='section selection'>
                            <p className='speisen-info'><strong>Ihre Auswahl:</strong> {food}</p>
                            <p className='speisen-info'><strong>Hauptzutaten:</strong> {zutaten.map((z, index) => {return <span className="comma-item" key={index}>{z}</span>})}</p>
                        </div>}
                        
                            {showInfoAcc1 && <div className='section padd-top'>
                                    <Speisen items={speisen} />
                                </div>
                            }
                        
                    </article>}

                </section>
            </main>
         
            <Button className='btn btn-big' onClick={handleSubmit}>Zur Weinempfehlung</Button>
        <FloatingButton />
        </>
    )
}

export default Menuscreen
