import React from 'react'
import { Navbar, Container } from 'react-bootstrap'
import { BsChevronCompactRight } from "react-icons/bs"

const Header = () => {

    return (
        <header>
            <Navbar bg="dark" variant='dark' expand="lg" collapseOnSelect>
                <Container className='custom-header-container'>
                    
                    <Navbar.Brand href="/">
                        <img
                        src="../../logo_genusswein.png"
                        alt="Titelbild Weinberater"
                        width='300px'
                        />
                    </Navbar.Brand>
                    <span>
                        <a className='btn round-corner' target="_blank" rel="noreferrer" href="https://genusswein.ch">Zum Wein-Shop <BsChevronCompactRight /></a>
                    </span>

                </Container>
            </Navbar>
        </header>
    )
}

export default Header
