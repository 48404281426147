import { useEffect } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { Container } from 'react-bootstrap'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Welcomescreen from './screens/Welcomescreen'
import Menuscreen from './screens/Menuscreen'
import Empfehlungsscreen from './screens/Empfehlungsscreen'
import Errorscreen from './screens/Errorscreen'
import ReactGA from "react-ga4";

function App() {

  useEffect(() => {
    ReactGA.initialize('G-TH69F9P9ZH');
    ReactGA.send({ hitType: "pageview", page: "/" });   
  })

  return (
    <Router>
      <Header />
        <main>
          <Container>
            <Routes>
              <Route path='/menu' element={<Menuscreen />} />
              <Route path='/empfehlung' element={<Empfehlungsscreen />} />
              <Route path='/' element={<Welcomescreen />} />
              <Route path='*' element={<Errorscreen />} />
            </Routes>
          </Container>
        </main>
      <Footer />
    </Router>
  );
}

export default App;
