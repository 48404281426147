import React from 'react'

const SalesBadge = () => {
  return (
    <div className='aktion'>
        <svg id="Ebene_2" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg" width="525" height="80" viewBox="0 0 525 472.54">
        <g id="Ebene_1-2" data-name="Ebene 1">
            <g>
            <polygon className="cls-1" points="100.46 352.54 116.81 352.54 108.56 307.69 100.46 352.54"/>
            <path className="cls-1" d="M342.56,301.54c-4.35,0-8.1,2.1-9.9,5.4-1.5,3-1.95,5.7-1.95,13.65v35.85c0,7.95,.45,10.65,1.95,13.65,1.8,3.3,5.55,5.4,9.9,5.4s8.1-2.1,9.9-5.4c1.65-3.15,1.95-5.55,1.95-13.65v-35.85c0-8.1-.3-10.5-1.95-13.65-1.8-3.3-5.55-5.4-9.9-5.4Z"/>
            <path className="cls-1" d="M519.86,215.18c-3.28-3.28-7.72-5.13-12.36-5.14h0s-31.83,0-31.83,0L300.86,64.37c5.78-10.31,6.96-22.58,3.25-33.81-4.22-13.39-14.62-23.94-27.95-28.35-13.33-4.41-27.97-2.14-39.34,6.09-11.38,8.23-18.1,21.43-18.07,35.48,.05,6.92,1.76,13.72,4.98,19.84L40.69,210.04H17.5c-4.64,.01-9.08,1.86-12.36,5.14-3.28,3.28-5.13,7.72-5.14,12.36v227.5c.01,4.64,1.86,9.08,5.14,12.36,3.28,3.28,7.72,5.13,12.36,5.14H507.5c4.64-.01,9.08-1.86,12.36-5.14,3.28-3.28,5.13-7.72,5.14-12.36V227.54c-.01-4.64-1.86-9.08-5.14-12.36ZM243.94,25.23c4.92-4.92,11.59-7.69,18.55-7.7h0c6.96,0,13.64,2.77,18.56,7.69,4.92,4.92,7.69,11.6,7.69,18.56s-2.77,13.64-7.69,18.56c-4.92,4.92-11.6,7.69-18.56,7.69s-13.64-2.77-18.56-7.69c-4.92-4.92-7.69-11.6-7.69-18.56,0-6.96,2.78-13.63,7.7-18.55ZM123.56,388.54l-4.2-21.75h-21.3l-4.05,21.75h-15.15l19.2-100.05h21.15l20.25,100.05h-15.9Zm70.2,0l-16.8-47.25-8.25,13.95v33.3h-15.45v-100.05h15.45v41.4l22.35-41.4h16.65l-21.15,36.3,24.15,63.75h-16.95Zm74.7-85.8h-19.5v85.8h-15.45v-85.8h-19.35v-14.25h54.3v14.25Zm29.4,85.8h-15.45v-100.05h15.45v100.05Zm72-31.8c0,11.25-1.65,18-5.7,23.7-4.5,6.3-12,9.6-21.6,9.6s-17.1-3.3-21.6-9.6c-4.05-5.7-5.7-12.45-5.7-23.7v-36.45c0-11.25,1.65-18,5.7-23.7,4.5-6.3,12-9.6,21.6-9.6s17.1,3.3,21.6,9.6c4.05,5.7,5.7,12.45,5.7,23.7v36.45Zm72.9,31.8h-13.8l-27-66.6v66.6h-14.7v-100.05h15.6l25.2,62.25v-62.25h14.7v100.05ZM68.7,210.03L234.69,77.27c7.64,6.53,17.33,10.16,27.38,10.26,10.05,.1,19.81-3.34,27.58-9.71l158.68,132.22H68.7Z"/>
            </g>
        </g>
        </svg>
    </div>
  )
}

export default SalesBadge