import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { courseSelectListReducer, foodSelectListReducer, zutatenSelectListReducer, topWeinEmpfehlungReducer } from './reducers/productReducers'

const reducer = combineReducers({
    courseSelectList: courseSelectListReducer,
    foodSelectList: foodSelectListReducer,
    zutatenSelectList: zutatenSelectListReducer,
    topWeinEmpfehlung: topWeinEmpfehlungReducer
});

const middleware = [thunk];

const store = createStore(
    reducer, 
    composeWithDevTools(applyMiddleware(...middleware))
    );

export default store