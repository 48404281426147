import React from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import Meta from '../components/Meta'
import { GiWineBottle } from "react-icons/gi";

const Errorscreen = () => {

    return (
        <>
            <div className='page-100'>
                <Meta title='Oops, die Seite wurde nicht gefunden' description='Gemeinsam finden wir den passenden Wein' keywords='weinberater, essen, fest, party, passend'/>
                <GiWineBottle style={{fontSize:'8rem'}}/>
                <h1 className='pt-5'>Oops, tut uns leid!</h1>
                <Col sm={12} md={6} lg={4} xl={3}>
                    <h2 className='primary-color'>Die gesuchte Seite konnte nicht gefunden werden.</h2>
                </Col>
                <Link className='btn btn-big' to='/'>Zurück</Link>
            </div>
        </>
    )
}

export default Errorscreen
