import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description, keywords }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
        </Helmet>
    )
}

Meta.defaultProps = {
    title: 'Ihr digitaler Weinberater',
    description: 'Der digitale Helfer für den passenden Wein zum Essen',
    keywords: 'Essen, Wein, Finden, Berater, Weinberater'
}

export default Meta
