import React, { useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { FaShoppingCart } from 'react-icons/fa'
import { ImPlus, ImMinus } from 'react-icons/im'
import { IoIosArrowForward } from 'react-icons/io'
import { BsStarFill } from "react-icons/bs";
import SalesBadge from '../components/SalesBadge';
import ReactGA from "react-ga4";

const Weinempfehlung = ({ wein, index }) => {

    const [qty, setQty] = useState(1)

    const handleSubmit = (id) => {
        ReactGA.event({
            category: "Weinauswahl",
            action: `Weinauswahl: ${wein.name}`,
            label: "wein", // optional
          });
          ReactGA.event({
            category: "Menge",
            action: `Menge: ${qty}`,
            label: "menge", // optional
          });
          ReactGA.event({
            category: "Summe",
            action: `Summe: ${wein.preis * qty}`,
            label: "summe", // optional
          });
        window.open(`https://genusswein.ch/warenkorb/?add-to-cart=${id}&quantity=${qty}`, '_blank');
    }

    const incrementCount = (qty) => {
        let menge = qty + 1
        setQty(menge);
      }

    const decrementCount = (qty) => {
        let menge = qty - 1
        if(menge <= 0) {
            return
        } else {
            setQty(menge)
        }
      }
    
    return <>
            {index === 1 ? (<h2 className='primary-color' style={{'paddingTop':'2.3rem'}}>Top-Wein-Empfehlung</h2>) : (<h2>Alternative {index-1}</h2>)}
            
            <div className='btn-container'>
                {<Card style={{'width': '60rem', 'marginTop':'5rem'}}>
                    {index === 1 && (<div className='topwein-icon-container'><div className='topwein-icon'><BsStarFill /> <BsStarFill /> <BsStarFill /> <BsStarFill /> <BsStarFill /></div><h5 className='topwein-text'>passt ausgezeichnet!</h5></div>) }
                    <Card.Img variant="top" src={wein.image} width='250rem' style={{'marginBottom':'1.5rem'}} />
                    <Card.Body>
                        <Card.Title as={'h3'}><strong>{wein.name}</strong></Card.Title>
                        <Card.Text>
                            {wein.description}
                        </Card.Text>
                        <Card.Text>
                            <a className='more-info' href={wein.link} rel="noreferrer" target="_blank"><IoIosArrowForward />Mehr erfahren</a>
                        </Card.Text>
                        <Card.Text className='price' style={{'paddingTop':'2rem','marginBottom':'-2rem'}}>
                            {wein.on_sale && <SalesBadge />}
                            <strong>CHF {wein.preis}</strong>
                        </Card.Text>
                        <Row className='quantity-wrapper'>
                            <Col style={{'display':'flex','justifyContent':'end'}} xs={5}>
                                <Button className='btn-light' onClick={() => decrementCount(qty)}><ImMinus /></Button>
                            </Col>
                            <Col xs={2}>
                                <div className='cart-quantity'>{qty}</div>
                            </Col>
                            <Col style={{'display':'flex','justifyContent':'start'}} xs={5}>
                                <Button className='btn-light' onClick={() => incrementCount(qty)}><ImPlus /></Button>
                            </Col>
                        </Row>
                        <Button className='btn-big' onClick={(e) => handleSubmit(wein.wpid)} variant="primary"><FaShoppingCart /> In den Warenkorb</Button>
                        <caption className='caption'>Die gewählte Anzahl Flaschen wird in den Warenkorb von genusswein.ch gelegt und Sie werden weitergeleitet.<br />Die Kaufabwicklung findet im Online-Shop von genusswein.ch statt.</caption>
                    </Card.Body>
                    </Card>}
            </div>
        </>
    }

export default Weinempfehlung