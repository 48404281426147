const menus = [

    {
        _id: '1',
        category: 'Vorspeise',
        name: 'Klare Suppe',
        img: '../../images/vorspeise/1.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz',
                ] 
            }
        ]
    },
    {
        _id: '2',
        category: 'Vorspeise',
        name: 'Feste Suppe',
        img: '../../images/vorspeise/2.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz',
                ] 
            }
        ]
    },
    {
        _id: '4',
        category: 'Vorspeise',
        name: 'Salate',
        img: '../../images/vorspeise/4.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'Käse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '5',
        category: 'Vorspeise',
        name: 'Tatar',
        img: '../../images/vorspeise/5.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '6',
        category: 'Vorspeise',
        name: 'Antipasti',
        img: '../../images/vorspeise/6.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'Käse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz',
                ] 
            }
        ]
    },
    {
        _id: '7',
        category: 'Vorspeise',
        name: 'Tapas',
        img: '../../images/vorspeise/7.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'Käse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz',
                ] 
            }
        ]
    },
    {
        _id: '8',
        category: 'Vorspeise',
        name: 'Terrine',
        img: '../../images/vorspeise/8.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '9',
        category: 'Vorspeise',
        name: 'Fisch',
        img: '../../images/vorspeise/9.jpg',
        zutaten: [
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Süsswasserfisch',
                    'Meeresfisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Zubereitung',
                subZutaten: [
                    'Dämpfen',
                    'Braten/Grillen'
                ] 
            },
            {
                zutatenName: 'Sauce',
                subZutaten: [
                    'Butterbasis',
                    'Weissweinbasis'
                ] 
            }
        ]
    },
    {
        _id: '10',
        category: 'Vorspeise',
        name: 'Vesperplättli',
        img: '../../images/vorspeise/10.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Speck',
                    'Wurst'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'Käse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse'
                ] 
            }
        ]
    },
    {
        _id: '11',
        category: 'Vorspeise',
        name: 'Eigene Kochvariationen',
        img: '../../images/vorspeise/11.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Süsswasserfisch',
                    'Meeresfisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'milder Käse',
                    'würziger Käse',
                    'Edelschimmelkäse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '35',
        category: 'Hauptgang',
        name: 'Eigene Kochvariationen',
        img: '../../images/vorspeise/11.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Süsswasserfisch',
                    'Meeresfisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'milder Käse',
                    'würziger Käse',
                    'Edelschimmelkäse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '12',
        category: 'Hauptgang',
        name: 'Risotto',
        img: '../../images/hauptspeise/1.jpg',
        zutaten: [
            {
                zutatenName: 'Ohne Zutaten',
                subZutaten: [
                    'Ohne Zutaten'
                ] 
            },
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'Käse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '13',
        category: 'Hauptgang',
        name: 'Pasta',
        img: '../../images/hauptspeise/2.jpg',
        zutaten: [
            {
                zutatenName: 'Ohne Zutaten',
                subZutaten: [
                    'Ohne Zutaten'
                ] 
            },
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'Käse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '14',
        category: 'Hauptgang',
        name: 'Burger/Frikadellen',
        img: '../../images/hauptspeise/3.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'Käse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '15',
        category: 'Hauptgang',
        name: 'Asiatische Speisen',
        img: '../../images/hauptspeise/4.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            },
            {
                zutatenName: 'Kokosnussmilch',
                subZutaten: [
                    'Kokosnussmilch'
                ] 
            },
            {
                zutatenName: 'Intensität',
                subZutaten: [
                    'Scharf'
                ] 
            }
        ]
    },
    {
        _id: '16',
        category: 'Hauptgang',
        name: 'Eintopf',
        img: '../../images/hauptspeise/5.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '17',
        category: 'Hauptgang',
        name: 'Fleischfondue',
        img: '../../images/hauptspeise/6.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse'
                ] 
            }
        ]
    },
    {
        _id: '18',
        category: 'Hauptgang',
        name: 'Käsefondue/Raclette',
        img: '../../images/hauptspeise/7.jpg',
        zutaten: [
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'milder Käse',
                    'würziger Käse'
                ] 
            }
        ]
    },
    {
        _id: '19',
        category: 'Hauptgang',
        name: 'Grilladen',
        img: '../../images/hauptspeise/8.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '20',
        category: 'Hauptgang',
        name: 'Braten',
        img: '../../images/hauptspeise/26.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse'
                ] 
            }
        ]
    },
    {
        _id: '21',
        category: 'Hauptgang',
        name: 'Geschnetzeltes',
        img: '../../images/hauptspeise/9.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '22',
        category: 'Hauptgang',
        name: 'Lasagne',
        img: '../../images/hauptspeise/10.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '23',
        category: 'Hauptgang',
        name: 'Spaghetti Bolognese',
        img: '../../images/hauptspeise/11.jpg',
        zutaten: [
            {
                zutatenName: 'Ohne Zutaten',
                subZutaten: [
                    'Ohne Zutaten'
                ] 
            },
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '24',
        category: 'Hauptgang',
        name: 'Gratin',
        img: '../../images/hauptspeise/12.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'Käse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '25',
        category: 'Hauptgang',
        name: 'Rösti',
        img: '../../images/hauptspeise/13.jpg',
        zutaten: [
            {
                zutatenName: 'Ohne Zutaten',
                subZutaten: [
                    'Ohne Zutaten'
                ] 
            },
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'Käse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '26',
        category: 'Hauptgang',
        name: 'Pizza',
        img: '../../images/hauptspeise/14.jpg',
        zutaten: [
            {
                zutatenName: 'Fleisch',
                subZutaten: [
                    'Geflügel',
                    'helles Fleisch',
                    'rotes Fleisch'
                ] 
            },
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'milder Käse',
                    'würziger Käse'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse',
                    'Fleischersatz'
                ] 
            }
        ]
    },
    {
        _id: '27',
        category: 'Hauptgang',
        name: 'Fisch',
        img: '../../images/hauptspeise/15.jpg',
        zutaten: [
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Süsswasserfisch',
                    'Meeresfisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Zubereitung',
                subZutaten: [
                    'Dämpfen',
                    'Braten/Grillen'
                ] 
            },
            {
                zutatenName: 'Sauce',
                subZutaten: [
                    'Butterbasis',
                    'Weissweinbasis'
                ] 
            }
        ]
    },
    {
        _id: '28',
        category: 'Hauptgang',
        name: 'Sushi',
        img: '../../images/hauptspeise/16.jpg',
        zutaten: [
            {
                zutatenName: 'Fisch',
                subZutaten: [
                    'Fisch',
                    'Meeresfrüchte'
                ] 
            },
            {
                zutatenName: 'Gemüse & Salate',
                subZutaten: [
                    'allg. Gemüse'
                ] 
            }
        ]
    },
    {
        _id: '29',
        category: 'Nachspeise',
        name: 'Früchte',
        img: '../../images/nachspeise/26.jpg',
        zutaten: [
            {
                zutatenName: 'Heimische Früchte',
                subZutaten: [
                    'Apfel',
                    'Birne',
                    'Zwetschgen',
                    'Kirschen'
                ] 
            },
            {
                zutatenName: 'Heimische Beerenfrüchte',
                subZutaten: [
                    'Himbeeren',
                    'Erdbeeren',
                    'Brombeeren',
                    'Heidelbeeren'
                ] 
            },
            {
                zutatenName: 'Tropische Früchte',
                subZutaten: [
                    'Papaya',
                    'Mango',
                    'Lichi'
                ] 
            }
        ]
    },
    {
        _id: '32',
        category: 'Nachspeise',
        name: 'Crème/Mousse/Flan',
        img: '../../images/nachspeise/29.jpg',
        zutaten: [
            {
                zutatenName: 'Diverses',
                subZutaten: [
                    'Schokolade',
                    'Vanille',
                    'Nüsse'
                ] 
            }
        ]
    },
    {
        _id: '33',
        category: 'Nachspeise',
        name: 'Torte/Cake/Diverses',
        img: '../../images/nachspeise/28.jpg',
        zutaten: [
            {
                zutatenName: 'Diverses',
                subZutaten: [
                    'Sauerrahm',
                    'Früchte'
                ] 
            }
        ]
    },
    {
        _id: '34',
        category: 'Nachspeise',
        name: 'Käse',
        img: '../../images/nachspeise/27.jpg',
        zutaten: [
            {
                zutatenName: 'Käse',
                subZutaten: [
                    'milder Käse',
                    'würziger Käse'
                ] 
            },
            {
                zutatenName: 'Blauschimmelkäse',
                subZutaten: [
                    'Roquefort',
                    'Stilton'
                ] 
            },
            {
                zutatenName: 'Weissschimmelkäse',
                subZutaten: [
                    'Brie'
                ] 
            },
            {
                zutatenName: 'Rotschmierkäse',
                subZutaten: [
                    'Munster'
                ] 
            }
        ]
    }
    

]

module.exports = menus