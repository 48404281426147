import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import Zutaten from './Zutaten';
import { updateFoodSelection, updateZutatenSelection } from '../actions/productActions'
import ReactGA from "react-ga4"

const Speisen = ({ items }) => {

    const [auswahlFood, setAuswahlFood] = useState('')
    const [show, setShow] = useState(false);
    const [selectedButton, setSelectedButton] = useState();

    const dispatch = useDispatch();

    const foodSelectList = useSelector(state => state.foodSelectList);
    const { food } = foodSelectList;

    const zutatenSelectList = useSelector(state => state.zutatenSelectList);
    const { zutaten } = zutatenSelectList;

    const handleAbort = () => {
      dispatch(updateFoodSelection([]))
      dispatch(updateZutatenSelection([]))
      setShow(false)
    }

    const handleClose = () => {
      setShow(false)
      if(food && zutaten?.length > 0) {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth',})
      }
    }
   
    const chooseMenu = (name) => {
      ReactGA.event({
        category: "Menüauswahl",
        action: `Menuauswahl: ${name}`,
        label: "menu", // optional
      });
      setAuswahlFood(name)
      dispatch(updateFoodSelection(name))
      if(food !== name) {
        dispatch(updateZutatenSelection([]))
      }
      setShow(true)
    }

    useEffect(() => {
      if(food) {
        setSelectedButton(food)
      } else {
        setSelectedButton('')
      }
    },[food])
    
        return (
          <>
            {items.map((item) => {

              const { _id, name, img } = item

               return <button key={_id} onClick={() => chooseMenu(name)} className={selectedButton === name ? 'section-center btn-wrapper isActive': 'section-center btn-wrapper'}>
                        <article className='menu-item' key={_id}>
                          <h4>{name}</h4>
                          <img src={img ? img : 'https://via.placeholder.com/180x70'} variant="top" width='200px' alt={name} />
                        </article> 
                      </button>
              })}

              <Modal show={show} onHide={handleAbort} fullscreen={true} backdrop="static" keyboard={false} >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h2>{auswahlFood}</h2>
                  <p>Welche Hauptzutat verwenden Sie für diesen Gang?</p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body><Zutaten auswahl={auswahlFood} menus={items}/></Modal.Body>
              <Modal.Footer>
                <Button variant="primary btn-big" onClick={handleClose}>
                  Übernehmen
                </Button>
              </Modal.Footer>
            </Modal>
            
          </>
        )
}

export default Speisen