import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import Meta from '../components/Meta'
import Loader from '../components/Loader'
import Stepper from '../components/Stepper'
import Message from '../components/Message'
import FloatingButton from '../components/FloatingButton'
import Weinempfehlung from '../components/Weinempfehlung'
import { IoIosWine } from "react-icons/io";
import { lookForTopWein } from '../actions/productActions'
import ReactGA from "react-ga4";

const Empfehlungsscreen = () => {

    const [showGang1, setShowGang1] = useState(true)
    const [showInfoAcc1, setShowInfoAcc1] = useState(true)

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const courseSelectList = useSelector(state => state.courseSelectList);
    const { course } = courseSelectList;

    const foodSelectList = useSelector(state => state.foodSelectList);
    const { food } = foodSelectList;

    const zutatenSelectList = useSelector(state => state.zutatenSelectList);
    const { zutaten } = zutatenSelectList;

    const topWeinEmpfehlung = useSelector(state => state.topWeinEmpfehlung);
    const { loading:loadingTopWein, error:errorTopWein, topWein } = topWeinEmpfehlung;

    useEffect(() => {
        dispatch(lookForTopWein(course, food, zutaten))   
    }, [])

    useEffect(() => {

        if(!course || !food || !zutaten) {
            navigate('/menu')
        }
        if(!topWein & !errorTopWein || topWein?.length === 0 & !errorTopWein){
            dispatch(lookForTopWein(course, food, zutaten))
        } 

        // eslint-disable-next-line
    },[dispatch, topWein, errorTopWein ])

    return (
        <>
        <Meta title='Weinempfehlung für ihr Menu | genusswein GmbH' description='Gemeinsam finden wir den passenden Wein' keywords='weinberater, essen, fest, party, passend'/>
        <Stepper colorStep2='#6e150e'/>
        <main className='container'>
                <h1 className='padding-bottom-medium'>Unsere Weinempfehlung</h1>
                <section className='info'>
                    {showGang1 && 
                        <article className='question'>
                        <header>
                            <h3>1. Gang</h3>
                            <p className='hero-paragraph'><IoIosWine className='icon-big'/><br />Nachfolgende Weine können wir Ihnen zu {food} mit {zutaten.map((z, index) => {return <span className="comma-item" key={index}>{z}</span>})} als {course} empfehlen.</p>
                            <button className='btn-round' onClick={() => setShowInfoAcc1(!showInfoAcc1) }>
                                {showInfoAcc1 ? <AiOutlineMinus /> : <AiOutlinePlus />}
                            </button>
                        </header>
                        <aside>
                        {showInfoAcc1 
                        }
                        </aside>
                        <main>
                            {showInfoAcc1 && (loadingTopWein ? <Loader /> : errorTopWein ? <Message variant='info'>{errorTopWein}.<br /><Link to='/menu'>Zurück</Link></Message> : topWein?.length > 0 & !loadingTopWein | !topWein ?  
                                (topWein.map((item, index) => {
                                    return <Fragment key={index}><Weinempfehlung wein={item} index={index+1} /></Fragment>
                                })) : (<Fragment><h2 className='primary-color' style={{'paddingTop':'2rem'}}>Leider haben wir keine Produkte gefunden.</h2></Fragment>)
                            ) }
                        </main>
                    </article>}
                </section>
            </main>
        <FloatingButton />
        </>
    )
}

export default Empfehlungsscreen
