import { 
    ZUTATEN_SELECT_LIST_LOAD,
    ZUTATEN_SELECT_LIST_SUCCESS,
    ZUTATEN_SELECT_LIST_FAIL,
    TOP_WEIN_EMPFEHLUNG_LOAD,
    TOP_WEIN_EMPFEHLUNG_SUCCESS,
    TOP_WEIN_EMPFEHLUNG_FAIL,
    COURSE_SELECT_LIST_LOAD,
    COURSE_SELECT_LIST_SUCCESS,
    COURSE_SELECT_LIST_FAIL,
    FOOD_SELECT_LIST_LOAD,
    FOOD_SELECT_LIST_SUCCESS,
    FOOD_SELECT_LIST_FAIL
} from '../constants/productConstants'

export const courseSelectListReducer = (state = { course: '' }, action) => {
    switch (action.type) {
        case COURSE_SELECT_LIST_LOAD:
            return { loading: true, course: '' }
        case COURSE_SELECT_LIST_SUCCESS:
            return { 
                loading: false, 
                course: action.payload
            }
        case COURSE_SELECT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const foodSelectListReducer = (state = { food: '' }, action) => {
    switch (action.type) {
        case FOOD_SELECT_LIST_LOAD:
            return { loading: true, food: '' }
        case FOOD_SELECT_LIST_SUCCESS:
            return { 
                loading: false, 
                food: action.payload
            }
        case FOOD_SELECT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const zutatenSelectListReducer = (state = { zutaten: [] }, action) => {
    switch (action.type) {
        case ZUTATEN_SELECT_LIST_LOAD:
            return { loading: true, zutaten: [] }
        case ZUTATEN_SELECT_LIST_SUCCESS:
            return { 
                loading: false, 
                zutaten: action.payload
            }
        case ZUTATEN_SELECT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const topWeinEmpfehlungReducer = (state = { topWein: {} }, action) => {
    switch (action.type) {
        case TOP_WEIN_EMPFEHLUNG_LOAD:
            return { loading: true, topWein: {} }
        case TOP_WEIN_EMPFEHLUNG_SUCCESS:
            return { 
                loading: false, 
                topWein: action.payload
            }
        case TOP_WEIN_EMPFEHLUNG_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}