import { 
    ZUTATEN_SELECT_LIST_LOAD,
    ZUTATEN_SELECT_LIST_SUCCESS,
    ZUTATEN_SELECT_LIST_FAIL,
    TOP_WEIN_EMPFEHLUNG_LOAD,
    TOP_WEIN_EMPFEHLUNG_SUCCESS,
    TOP_WEIN_EMPFEHLUNG_FAIL,
    COURSE_SELECT_LIST_LOAD,
    COURSE_SELECT_LIST_SUCCESS,
    COURSE_SELECT_LIST_FAIL,
    FOOD_SELECT_LIST_LOAD,
    FOOD_SELECT_LIST_SUCCESS,
    FOOD_SELECT_LIST_FAIL
} from '../constants/productConstants'
import axios from 'axios'

export const updateCourseSelection = (course) => async (dispatch) =>  {
    try {
        
        dispatch({ type: COURSE_SELECT_LIST_LOAD })
                
        dispatch({ 
            type: COURSE_SELECT_LIST_SUCCESS, 
            payload: course
        })
    } catch (error) {
        dispatch({ 
            type: COURSE_SELECT_LIST_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}

export const updateFoodSelection = (name) => async (dispatch) =>  {
    try {
        
        dispatch({ type: FOOD_SELECT_LIST_LOAD })
                
        dispatch({ 
            type: FOOD_SELECT_LIST_SUCCESS, 
            payload: name
        })
    } catch (error) {
        dispatch({ 
            type: FOOD_SELECT_LIST_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}

export const updateZutatenSelection = (list) => async (dispatch) =>  {
    try {
        
        dispatch({ type: ZUTATEN_SELECT_LIST_LOAD })
                
        dispatch({ 
            type: ZUTATEN_SELECT_LIST_SUCCESS, 
            payload: list
        })
    } catch (error) {
        dispatch({ 
            type: ZUTATEN_SELECT_LIST_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}

export const lookForTopWein = (course, food, zutaten) => async (dispatch) =>  {

    try {
        
        dispatch({ type: TOP_WEIN_EMPFEHLUNG_LOAD })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        const body = {
            course: course,
            food: food,
            zutaten: zutaten,
          };

        const {data} = await axios.post(`/api/products/recommendation`, body, config);

        dispatch({ 
            type: TOP_WEIN_EMPFEHLUNG_SUCCESS, 
            payload: data
        })

        } catch (error) {
            dispatch({ 
                type: TOP_WEIN_EMPFEHLUNG_FAIL, 
                payload: error.response && error.response.data.message ? error.response.data.message : error.message 
            })
        }
}