export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const COURSE_SELECT_LIST_LOAD = 'COURSE_SELECT_LIST_LOAD'
export const COURSE_SELECT_LIST_SUCCESS = 'COURSE_SELECT_LIST_SUCCESS'
export const COURSE_SELECT_LIST_FAIL = 'COURSE_SELECT_LIST_FAIL'

export const FOOD_SELECT_LIST_LOAD = 'FOOD_SELECT_LIST_LOAD'
export const FOOD_SELECT_LIST_SUCCESS = 'FOOD_SELECT_LIST_SUCCESS'
export const FOOD_SELECT_LIST_FAIL = 'FOOD_SELECT_LIST_FAIL'

export const ZUTATEN_SELECT_LIST_LOAD = 'ZUTATEN_SELECT_LIST_LOAD'
export const ZUTATEN_SELECT_LIST_SUCCESS = 'ZUTATEN_SELECT_LIST_SUCCESS'
export const ZUTATEN_SELECT_LIST_FAIL = 'ZUTATEN_SELECT_LIST_FAIL'

export const TOP_WEIN_EMPFEHLUNG_LOAD = 'TOP_WEIN_EMPFEHLUNG_LOAD'
export const TOP_WEIN_EMPFEHLUNG_SUCCESS = 'TOP_WEIN_EMPFEHLUNG_SUCCESS'
export const TOP_WEIN_EMPFEHLUNG_FAIL = 'TOP_WEIN_EMPFEHLUNG_FAIL'